document.addEventListener('turbolinks:load', function() {
    gtag('config', 'UA-74187457-1')
});
document.addEventListener("turbolinks:load", function(event) {

	if (window.innerWidth >= 1024) {
		const cardSwiper = new Swiper('.card-slider-container', {
			// Optional parameters
			direction: 'horizontal',
			loop: true,
			slidesPerView: 4,
			spaceBetween: 20,

			// Navigation arrows
			navigation: {
			  nextEl: '.swiper-button-next',
			  prevEl: '.swiper-button-prev',
			}
		});

		const reviewsSwiper = new Swiper('.reviews-slider', {
			// Optional parameters
			direction: 'horizontal',
			loop: true,
			slidesPerView: 3,
			spaceBetween: 20,

			// Navigation arrows
			navigation: {
			  nextEl: '.swiper-button-next',
			  prevEl: '.swiper-button-prev',
			}
		});
	}

	//Scripts for Home page
	const catalogTitle = document.getElementsByClassName('catalog-title')[0];
	const catalogMobContent = document.getElementsByClassName('catalog-content-mob')[0];
	const catalogDropdown = document.getElementsByClassName('catalog-sub-dropdown');
	const catalogLink = document.getElementsByClassName('catalog-nav-item-link');
	const catalogSubContent = document.getElementsByClassName('catalog-nav-item-sub');

	catalogTitle.onclick = toggleMobCatalog;

	//"Click" function for Link in Catalog (for mobile)
	if (window.innerWidth < 1024) {

		for(let i = 0; i < catalogLink.length; i++){
			if (catalogLink[i].dataset.withSubcategories === 'true'){
				catalogLink[i].removeAttribute("href");
				catalogLink[i].addEventListener("click",function(){
					this.classList.toggle('catalog-nav-item-link-open');
					this.nextElementSibling && this.nextElementSibling.classList.toggle('catalog-nav-item-sub-open');
				})
			}
		}
	}

	//Toggle whole dropdown (Mobile)
	function toggleMobCatalog() {
		catalogMobContent.classList.toggle('catalog-content-mob-open');
		catalogTitle.classList.toggle('catalog-title-blink');
	}

	for(let i = 0; i < catalogDropdown.length; i++){

		catalogDropdown[i].addEventListener("click",function(){

		if (this.childNodes[3].classList.contains('catalog-sub-dropdown-list-open')) {
			this.childNodes[3].classList.remove("catalog-sub-dropdown-list-open");
			this.classList.remove("catalog-sub-dropdown-open");
		} else {
			this.childNodes[3].classList.add("catalog-sub-dropdown-list-open");
			this.classList.add("catalog-sub-dropdown-open");
		}
	  })
	}

	//Turn on Slider on Desktop
	if (window.innerWidth >= 1024) {
		const cardSwiper = new Swiper('.card-slider-container', {
			// Optional parameters
			direction: 'horizontal',
			loop: true,
			slidesPerView: 4,
			spaceBetween: 20,

			// Navigation arrows
			navigation: {
			  nextEl: '.swiper-button-next',
			  prevEl: '.swiper-button-prev',
			}
		});

		const reviewsSwiper = new Swiper('.reviews-slider', {
			// Optional parameters
			direction: 'horizontal',
			loop: true,
			slidesPerView: 3,
			spaceBetween: 20,

			// Navigation arrows
			navigation: {
			  nextEl: '.swiper-button-next',
			  prevEl: '.swiper-button-prev',
			}
		});
	}

});
