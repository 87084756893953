document.addEventListener('turbolinks:load', function() {
  if(window.ga && ga.loaded) {
    gtag('config', 'UA-74187457-1')
  }
});
document.addEventListener("turbolinks:load", function(event) {

	//Scripts for Header
    const dropdownArrow = document.getElementsByClassName('phone-block-row-arrow')[0];
    const dropdownNumbers = document.getElementsByClassName('phone-block-additional')[0];
	const menuToggleBtn = document.getElementsByClassName('nav-toggle');
	const nav = document.getElementsByClassName('nav')[0];
	const searchBtn = document.getElementsByClassName('search-block-icon')[0];
	const searchField = document.getElementsByClassName('search-block-wrapper')[0];
	const searchFieldCloseBtn = document.getElementsByClassName('search-block-wrapper-close')[0];

    dropdownArrow.onclick = toggleDropdown;
	searchBtn.onclick = searchFieldToggle;
	searchFieldCloseBtn.onclick = searchFieldToggle;
	menuToggleBtn.onclick = menuToggle;

	//For Dropdown with Phone numbers in Header
	function toggleDropdown() {
		dropdownNumbers.classList.toggle('phone-block-additional-show');
		dropdownArrow.classList.toggle('phone-block-row-arrow-open');
	}

	//Toggle Search field in Header
	function searchFieldToggle() {
		searchField.classList.toggle('search-block-wrapper-open');
		dropdownNumbers.classList.remove("phone-block-additional-show");
		dropdownArrow.classList.remove("phone-block-row-arrow-open");
	}

	for (i = 0; i < menuToggleBtn.length; ++i) {
		menuToggleBtn[i].onclick = () => menuToggle(i);
	};

	function menuToggle(i) {
		menuToggleBtn[0].classList.toggle("nav-toggle-open");
		nav.classList.toggle("nav-open");
	}

	//search request
	const searchFieldProducts = document.getElementsByClassName('search-block-field-input');
	const sendSearchButtons = document.getElementsByClassName("search-block-field-button");

	let productSearchVal = "";

	for (i = 0; i < searchFieldProducts.length; ++i) {
		searchFieldProducts[i].oninput = function() {
			productSearchVal = this.value;
		}
		searchFieldProducts[i].addEventListener("keyup", function(event) {
			if (event.keyCode === 13) {
			  event.preventDefault();
        productSearchVal = event.currentTarget.value || ""
        sendSearchRequest()
			}
		});
	}

	for (i = 0; i < sendSearchButtons.length; ++i) {
		sendSearchButtons[i].onclick = function() {
			if(productSearchVal.length) {
				sendSearchRequest()
			}
		}
	}

	function sendSearchRequest() {
    let ukr_locale= window.location.href.includes('/ua') ? '/ua' : ''

    if (productSearchVal.length > 0) {
      window.location.href = `${ukr_locale}/products?search_str=${productSearchVal}`;
    } else {
      window.location.href = `${ukr_locale}/products`;
    }
	}

});
