document.addEventListener("turbolinks:load", function(event) {

     //for adding product to basket
     const addIcon = Array.from(document.querySelectorAll(".main-card-bying-block-basket"));
     const basketCounter = document.getElementsByClassName("basket-amount")[0];
     const basket = document.getElementsByClassName("basket-button")[0];
     const addToBasketBtn = Array.from(document.getElementsByClassName("addToBasketBtn"));
     let productNumber = "";

    //product counter
    if(document.getElementsByClassName("number")[0]) {

      productNumber = document.getElementsByClassName("number")[0];
      let increase = document.getElementsByClassName("increase")[0];
      let reduce = document.getElementsByClassName("reduce")[0];

      increase.onclick = increasePrice;
      reduce.onclick = reducePrice;

      let currentNum = productNumber.innerHTML - 0;

      function increasePrice() {
          currentNum = currentNum + 1;
          productNumber.innerHTML = currentNum;
          reduce.classList.remove("reduce-disabled");
      }

      function reducePrice() {
          if(currentNum === 2) {
              currentNum = currentNum -1;
              productNumber.innerHTML = currentNum;
              reduce.classList.add("reduce-disabled");
              console.log(currentNum)
              return
          } else if (currentNum === 1) {
              return
          } else {
              currentNum = currentNum - 1;
              productNumber.innerHTML = currentNum;
          }

      }

      addToBasketBtn.forEach(btn => {

        btn.onclick = function() {
          let productId = document.getElementsByClassName("product")[0].id;

          let obj = {
            product_id: productId,
            amount: currentNum
          }

          addToBasketRequest(obj)

      }})
    }

     if(Number(basketCounter.innerHTML)) {
        basket.classList.add("basket-button-visible");
     }

    addIcon.forEach(btn => {
		    btn.onclick = function() {
            let productId = btn.closest(".main-card-wrap").id;

            let obj = {
                product_id: productId
            }

            addToBasketRequest(obj)
    }
	});

  function addToBasketRequest(obj) {
    let authToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': authToken },
        body: JSON.stringify(obj)
      };

      fetch('/personal/add_to_cart', requestOptions)
      .then((response) => {
        if (response.status > 204) {
          throw new Error("Not success");
        }
        return response.json();
      })
      .then((data) => {
        basket.classList.add("basket-button-visible");
        basketCounter.innerHTML = data.counter;
        currentNum = 1;
        if(document.getElementsByClassName("number")[0]) {
          productNumber.innerHTML = currentNum;
        }
      })
      .catch((error) => console.log(error));
    }

    	//Scripts for Modal
      if(!document.getElementById("catalog")) {

        if (document.querySelectorAll(".main-card-buy_btn-button")) {
          let ModalCardBtns = Array.from(document.querySelectorAll(".main-card-buy_btn-button"));
          let ModalCard = document.getElementsByClassName("main-modal-card")[0];
          const ModalCardOverlay = document.getElementsByClassName("main-modal-overlay");
          const ModalCloseBtn = document.getElementsByClassName("main-modal-content-close");
          const formFieldName = document.getElementsByClassName("form-field-text");
          const formFieldPhone = document.getElementsByClassName("form-field-number");
          const submitButton = document.getElementsByClassName("submit-btn");

          let name = "";
          let phone = "";
          let productId = "";

          for (i = 0; i < formFieldName.length; ++i) {
            formFieldName[i].oninput = (e) => {
              name = e.target.value;
              console.log(name)
            };
          };

          for (i = 0; i < formFieldPhone.length; ++i) {
            formFieldPhone[i].oninput = (e) => {
              phone = e.target.value;
              console.log(phone)
            };
          };

          for (i = 0; i < submitButton.length; ++i) {
            submitButton[i].onclick = function() {
              let modalClass = this.closest(".main-modal");

              let modalEndpoint = "";
              let obj = {
                product_id: productId,
                name: name,
                phone: phone
              }
              if(modalClass.classList.contains("main-modal-card")) {
                modalEndpoint = "/personal/one_click_purchase"
              }else if(modalClass.classList.contains("main-modal-price")) {
                modalEndpoint = "/products/clarify_price";
              } else {
                modalEndpoint = "/products/notify_existance";
              }
              sendModalRequest(modalEndpoint, obj)
            }
          };

          function sendModalRequest(endpoint, obj) {

            let authToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

            const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': authToken },
              body: JSON.stringify(obj)
            };

            fetch(endpoint, requestOptions)
            .then((response) => {
              name = "";
              phone = "";
              productId = "";
              ModalCard.classList.remove("main-modal-visible");

              if (response.status > 204) {
                document.getElementsByClassName("main-modal-info-not-success")[0].classList.add("main-modal-visible");
                throw new Error("Not success");
              } else {
                document.getElementsByClassName("main-modal-info-success")[0].classList.add("main-modal-visible");
              }
            })
            .catch((error) => console.log(error));

          }


          ModalCardBtns.forEach(btn => {
            btn.onclick = function() {
              if(this.dataset.modal === "buy") {
                ModalCard = document.getElementsByClassName("main-modal-card")[0];
              } else {
                ModalCard = document.getElementsByClassName("main-modal-price")[0];
              }
              ModalCard.classList.add("main-modal-visible");
              if(btn.closest(".product")) {
                productId = btn.closest(".product").id;
              } else {
                productId = btn.closest(".main-card-wrap").id;
              }
            }
          });

          for (i = 0; i < ModalCardOverlay.length; ++i) {
            ModalCardOverlay[i].onclick = function() {
              this.closest(".main-modal").classList.remove("main-modal-visible");
              name = "";
              phone = "";
              productId = "";
            }
          }

          for (i = 0; i < ModalCloseBtn.length; ++i) {
            ModalCloseBtn[i].onclick = function() {
              this.closest(".main-modal").classList.remove("main-modal-visible");
              name = "";
              phone = "";
              productId = "";
            }
          }
        }
      }
})
